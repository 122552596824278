import amazon from "../../assets/images/clientsSlider/new/amazon_prime.png";
import bayer from "../../assets/images/clientsSlider/new/bayer.png";
import disney from "../../assets/images/clientsSlider/new/disney.png";
import gerdau from "../../assets/images/clientsSlider/new/gerdau.png";
import globoplay from "../../assets/images/clientsSlider/new/globoplay.png";
import hotmart from "../../assets/images/clientsSlider/new/hotmart.png";
import localiza from "../../assets/images/clientsSlider/new/localiza.png";
import mercado_livre from "../../assets/images/clientsSlider/new/mercado_livre.png";
import meta from "../../assets/images/clientsSlider/new/meta.png";
import microsoft from "../../assets/images/clientsSlider/new/microsoft.png";
import mondelez from "../../assets/images/clientsSlider/new/mondelez.png";
import nba from "../../assets/images/clientsSlider/new/nba.png";
import netflix from "../../assets/images/clientsSlider/new/netflix.png";
import nu from "../../assets/images/clientsSlider/new/nu.png";
import outback from "../../assets/images/clientsSlider/new/outback.png";
import paramount from "../../assets/images/clientsSlider/new/paramount.png";
import tiktok from "../../assets/images/clientsSlider/new/tiktok.png";
import tres_coracoes from "../../assets/images/clientsSlider/new/tres_coracoes.png";
import vale from "../../assets/images/clientsSlider/new/vale.png";
import xbox from "../../assets/images/clientsSlider/new/xbox.png";

// import netflix from "../../assets/images/clientsSlider/netflix.png";
// import meta from "../../assets/images/clientsSlider/meta.png";
// import bayer from "../../assets/images/clientsSlider/bayer.png";
// import cnn from "../../assets/images/clientsSlider/cnn_brasil.png";
// import vale from "../../assets/images/clientsSlider/vale.png";
import xpinvestimentos from "../../assets/images/clientsSlider/xp.png";
// import rockinrio from "../../assets/images/clientsSlider/rock_in_rio.png";
// import microsoft from "../../assets/images/clientsSlider/microsoft.png";
// import olx from "../../assets/images/clientsSlider/olx.png";
// import nove9 from "../../assets/images/clientsSlider/99.png";
// import activision from "../../assets/images/clientsSlider/activision.png";
// import dow from "../../assets/images/clientsSlider/dow.png";
// import outback from "../../assets/images/clientsSlider/outback.png";
// import chillibeans from "../../assets/images/clientsSlider/chilli_beans.png";
// import marykay from "../../assets/images/clientsSlider/mary_kay.png";
// import cocacola from "../../assets/images/clientsSlider/coca_cola.png";
// import bradesco from "../../assets/images/clientsSlider/bradesco.png";
// import roche from "../../assets/images/clientsSlider/roche.png";
// import movile from "../../assets/images/clientsSlider/movile.png";

export default [
  {
    index: 1,
    image: amazon,
  },
  {
    index: 2,
    image: bayer,
  },
  {
    index: 3,
    image: disney,
  },
  {
    index: 4,
    image: gerdau,
  },
  {
    index: 5,
    image: globoplay,
  },
  {
    index: 7,
    image: hotmart,
  },
  {
    index: 8,
    image: localiza,
  },
  {
    index: 19,
    image: mercado_livre,
  },
  {
    index: 20,
    image: meta,
  },
  {
    index: 11,
    image: microsoft,
  },
  {
    index: 18,
    image: mondelez,
  },
  {
    index: 6,
    image: nba,
  },
  {
    index: 23,
    image: netflix,
  },
  {
    index: 22,
    image: nu,
  },
  {
    index: 24,
    image: outback,
  },
  {
    index: 25,
    image: paramount,
  },
  {
    index: 26,
    image: tiktok,
  },
  {
    index: 27,
    image: tres_coracoes,
  },
  {
    index: 28,
    image: vale,
  },
  {
    index: 29,
    image: xbox,
  }
];
