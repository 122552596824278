import React from 'react'
import Img from 'gatsby-image'
import SliderClients from 'components/SliderClients'
import Title from 'components/Title'
import * as S from './Clients.styles'
import { useIntl } from 'gatsby-plugin-intl'
import images from './Clients.mock.js'

const settings = {
  slidesToShow: 8,
  infinite: false,
  responsive: [
    {
      breakpoint: 1370,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
      },
    },
  ],
}

const Clients = () => {
  const { formatMessage } = useIntl()

  return (
    <S.Wrapper id="clients">
      <Title>{formatMessage({ id: 'clients.title' })}</Title>
      <S.SlideWrapper>
        {images?.map(({ index, image }) => {
          return (
            <S.CardImage key={index} index={index}>
              <S.Img src={image} />
            </S.CardImage>
          )
        })}
      </S.SlideWrapper>
    </S.Wrapper>
  )
}

export default Clients
